export const HINTS = [
  'Charm Used in the TriWizard Tournament',
  'Filius Flitwick',
  'Chocolate',
  '📷',
  'Minister for Magic',
  '🐭',
  'Dark Magic',
  '"There is nothing worse than BLANK, Dumbledore!"',
  'Aunt',
  'Friend of Winky',
  'Chief Warlock of the Wizengamot',
  'Deathly Hallows',
  'Kwikspell',
  '👻',
  'What Muggles are Lacking',
  'Graap',
  'Cedric Girlfriend',
  'Chaser and Seeker',
  'Son of a Death Eater',
  'Auror',
  'Knox',
  'Gryffindor, Ravenclaw, Slytherin, Hufflepuff',
  'Inquisitorial',
  'Law Enforcement Official',
  'Deathly Hallows',
  'Son of Fleamont and Euphemia',
  'Pub in London',
  'Victum of Fenir Greyback',
  '🐺',
  'Pomfrey',
  'Kiler of Bellatrix Lestrange',
  '🌕',
  '1/2 BLANK 🤴',
  'Twin',
  'Fred and George',
  'Entrance Under the Whomping Willow',
  'Sorcerers or Philosophers',
  'Kwikspell',
  'Metamorphmagus',
  'Magical Beast',
  'Native to Bulgaria',
  '"Always"',
  'Ollivander',
  'Game',
  '🧹',
  '👻',
  'Test',
  'Title',
  'Writing Instrument',
  'Hogwarts Express',
  '🧙‍♀️',
  'History of Magic',
  'Head of the Department of Magical Law Enforcement',
  'Joke Shop',
  '🟩👻',
  'Slytherin Student',
  'Magical Creature Studied in Defense Against the Dark Arts',
  '💰',
  'Gringotts',
  '🍀',
  '🧆🦐🥐🥓🍲🍖🥩',
  'Friend of Dobby',
  'Bat-BLANK Hex',
  '🐺',
  '🦉',
  'Where Quidditch is Played',
  '🟨👻',
  'Veela',
  'Son of a Death Eater',
  'Rolanda',
  '🐕',
  'Lumos For Example',
  'How the Ministry of Magic Knowns When Magic is Performed Around Underaged Witches/Wizards',
  'A Horcrux',
  'Squib',
  'Can Destroy a Horcrux',
  'Quidditch Equipment',
  'Nagini',
  'A Wizards Attire',
  '🟨',
  'Astronomy',
  'Prefect and Head Boy',
  'Magical Plant',
  'Magical Beast',
  'Pure Blood Family',
  'Auror',
  'Located Behind The Leaky Cauldron',
  'Unforgivable Curse',
  '🗞️',
  'Chaser',
  'Pumpkin',
]
