export const VALID_GUESSES = [
  'accio',
  'charm',
  'frogs',
  'colin',
  'fudge',
  'peter',
  'curse',
  'death',
  'marge',
  'dobby',
  'albus',
  'elder',
  'filch',
  'ghost',
  'magic',
  'giant',
  'chang',
  'ginny',
  'goyle',
  'harry',
  'lumos',
  'house',
  'squad',
  'auror',
  'cloak',
  'james',
  'leaky',
  'brown',
  'lupin',
  'madam',
  'molly',
  'moony',
  'blood',
  'patil',
  'twins',
  'shack',
  'stone',
  'squib',
  'tonks',
  'troll',
  'veela',
  'snape',
  'wands',
  'chess',
  'comet',
  'ghoul',
  'newts',
  'order',
  'quill',
  'train',
  'witch',
  'binns',
  'barty',
  'zonko',
  'baron',
  'pansy',
  'pixie',
  'knuts',
  'vault',
  'felix',
  'feast',
  'winky',
  'bogey',
  'remus',
  'errol',
  'pitch',
  'friar',
  'fleur',
  'draco',
  'hooch',
  'black',
  'spell',
  'trace',
  'diary',
  'argus',
  'sword',
  'broom',
  'snake',
  'robes',
  'helga',
  'tower',
  'percy',
  'snare',
  'gnome',
  'eater',
  'gaunt',
  'moody',
  'alley',
  'avada',
  'daily',
  'katie',
  'pasty',
]
